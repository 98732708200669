import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SEOContent } from "../../shared/page.types";
import { useI18next, Helmet } from "gatsby-plugin-react-i18next";

const SEO = ({
  seoTitle: title,
  seoDescription: description,
  image,
  url,
}: SEOContent) => {
  const { site, seoFallbackFR, seoFallbackEN } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        seoFallbackFR: contentfulSeo(
          identifier: { eq: "seo_fallback" }
          node_locale: { eq: "fr" }
        ) {
          seoTitle
          seoDescription
          image {
            media {
              title
              description
              file {
                url
              }
            }
          }
        }
        seoFallbackEN: contentfulSeo(
          identifier: { eq: "seo_fallback" }
          node_locale: { eq: "fr" }
        ) {
          seoTitle
          seoDescription
          image {
            media {
              title
              description
              file {
                url
              }
            }
          }
        }
      }
    `
  );
  const { language } = useI18next();

  const seoContent = language === "en" ? seoFallbackEN : seoFallbackFR;

  const fallBackImage = seoContent?.image.media.file.url;
  const imageUrl = `https:${image?.media?.file?.url || fallBackImage}`;

  const alt = `${
    image?.media?.description || seoContent.image.media.description
  } - ${title}`;

  const fallBackAlt = seoContent.image.media.description;

  const metaDescription =
    description || seoContent.siteDescription || site.siteMetadata.description;

  const metaTitle = title || seoContent.siteTitle || site.siteMetadata.title;

  const microDataSchema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": "https://gameon.studio/#website",
        url: "https://gameon.studio",
        name: "Studio Game On",
      },
      {
        "@type": "ImageObject",
        "@id": "https://gameon.studio/#primaryimage",
        url: imageUrl,
        width: 1650,
        height: 697,
      },
      {
        "@type": "WebPage",
        "@id": "https://gameon.studio/#webpage",
        url: "https://gameon.studio",
        inLanguage: "en",
        name: metaTitle,
        isPartOf: { "@id": "https://gameon.studio/#website" },
        primaryImageOfPage: { "@id": "https://gameon.studio/#primaryimage" },
        datePublished: "2021-02-19",
        dateModified: "2021-02-19",
        description: metaDescription,
      },
    ],
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={metaTitle}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          property: `og:image:alt`,
          content: alt || fallBackAlt,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image`,
          content: imageUrl,
        },
        {
          property: `twitter:image:alt`,
          content: alt,
        },
        {
          name: `twitter:creator`,
          content: "@gameon_studio",
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: `max-snippet:-1, max-image-preview:large, max-video-preview:-1`,
        },
      ]}
    >
      <script type="application/ld+json">
        {JSON.stringify(microDataSchema)}
      </script>
    </Helmet>
  );
};

export default SEO;
