import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Menu as BurgerMenu, X } from "react-feather";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

import { graphql, useStaticQuery } from "gatsby";
import { MenuList } from "./MenuList.component";

import "./menu.scss";
import { useResponsive } from "../../hooks/useResponsive";
import Logo from "../logo/Logo.component";

const Menu = () => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const { language } = useI18next();
  const { t } = useTranslation();

  const { client, isMedium } = useResponsive();

  const menuData = useStaticQuery(
    graphql`
      query menu {
        menuFr: contentfulMenu(
          node_locale: { eq: "fr" }
          contentful_id: { eq: "5fTOxqe8PPYzRz4S9eEGut" }
        ) {
          menuLinks {
            label
            slug
          }
        }
        menuEn: contentfulMenu(
          node_locale: { eq: "en" }
          contentful_id: { eq: "5fTOxqe8PPYzRz4S9eEGut" }
        ) {
          menuLinks {
            label
            slug
          }
        }
        logo: contentfulAsset(contentful_id: { eq: "7yz4vgbhlW4V4v2BYgLW95" }) {
          title
          description
          file {
            url
          }
        }
      }
    `
  );

  const data =
    language === "en" ? menuData.menuEn.menuLinks : menuData.menuFr.menuLinks;

  const toggleMenu = () => {
    setDisplayMenu(!displayMenu);
  };

  if (!client) {
    return <></>;
  }

  const header = document.querySelector("header");

  return (
    <>
      <button
        onClick={toggleMenu}
        className={"icon-menu"}
        aria-label={displayMenu ? t("ariaMenuClose") : t("ariaMenu")}
      >
        {displayMenu ? (
          <X size={isMedium ? 48 : 24} />
        ) : (
          <BurgerMenu size={isMedium ? 48 : 24} />
        )}
      </button>
      {displayMenu &&
        ReactDOM.createPortal(
          <>
            <Logo className={"logo-nav"} />
            <nav role="main navigation" className={"menu-wrapper"}>
              <MenuList data={data} />
            </nav>
          </>,
          header || document.body
        )}
    </>
  );
};
export default Menu;
