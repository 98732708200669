import React, { ReactNode, useEffect } from "react";

import { PageLayout } from "./PageLayout.component";
import Footer from "../footer/Footer.component";
import Header from "../header/Header.component";

import "../../shared/style/tailwind.scss";
import "./layout.scss";
import Intro from "../homepage/intro/Intro.component";
import { SEOContent } from "../../shared/page.types";
import SEO from "../seo/SEO.component";
import { getLanguageFromStorage } from "../../shared/utils/i18n.utils";
import { ContentfulVideo } from "../../shared/contentful/contentful.types";

type IntroContent = {
  introClips: ContentfulVideo[];
  intro: any;
};

type Props = {
  children: ReactNode;
  pageNavigation: () => JSX.Element;
  introContent?: IntroContent;
  seo: SEOContent;
};

export const Layout = ({
  children,
  pageNavigation,
  introContent,
  seo,
}: Props) => {
  const PageNav = pageNavigation;

  useEffect(() => {
    getLanguageFromStorage();
  }, []);

  return (
    <>
      <SEO {...seo} />
      <PageLayout>
        <div className="page-layout">
          <Header />
          <main>
            {introContent && (
              <Intro
                introClips={introContent.introClips}
                intro={introContent.intro}
              />
            )}
            <div className="layout-grid">
              {children}
              <nav className={"page-nav"} aria-label="page navigation">
                <PageNav />
              </nav>
            </div>
          </main>
          <Footer />
        </div>
      </PageLayout>
    </>
  );
};
