import React from "react";
import { useI18next, Link } from "gatsby-plugin-react-i18next";

import "./menu.scss";

type MenuItem = {
  label: string;
  slug: string;
};
type Props = {
  data: MenuItem[];
};

const Careerslink = ({ label }: MenuItem) => (
  <a
    href="https://careers.smartrecruiters.com/GameOnStudio"
    target="_blank"
    rel="noopener noreferrer"
  >
    {label}
  </a>
);

export const MenuList = ({ data }: Props) => {
  const { languages, language, originalPath } = useI18next();
  const otherLng = languages.find((l) => l !== language);

  return (
    <div className={"content-wrapper"}>
      <ul className={"pages-link-wrapper"}>
        {data.map((d) => (
          <li key={d.slug} className={"menu-item"}>
            {d.slug === "careers" ? (
              <Careerslink {...d} />
            ) : (
              <Link to={`/${d.slug}`}>{d.label}</Link>
            )}
          </li>
        ))}
      </ul>
      <Link to={originalPath} language={otherLng} className={"lang-switcher"}>
        {otherLng === "fr" ? "français" : "english"}
      </Link>
    </div>
  );
};
