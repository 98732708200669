import React, { useEffect, useRef, useState } from "react";
import { ContentfulVideo } from "../../../shared/contentful/contentful.types";

type Props = {
  introClips: ContentfulVideo[];
};

export const VideoClip = ({ introClips }: Props) => {
  const [videoStep, setVideoStep] = useState(0);
  const [playVideo, setPlayVideo] = useState(true);
  const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setVideoSrc(introClips[0].asset?.file.url);
  }, []);

  useEffect(() => {
    setVideoSrc(introClips[videoStep]?.asset?.file.url);
    setPlayVideo(true);
  }, [videoStep]);

  const handleVideoEnd = () => {
    setPlayVideo(false);
    setVideoStep(videoStep + 1 >= introClips.length ? 0 : videoStep + 1);
  };

  const handleLoadStart = () => {};

  return (
    <div className={"videoclip-wrapper"}>
      {videoSrc && playVideo && (
        <video
          ref={videoRef}
          preload={"auto"}
          height={"100vh"}
          muted
          controls={false}
          autoPlay
          onLoadStart={handleLoadStart}
          onEnded={handleVideoEnd}
          playsInline={true}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      )}
    </div>
  );
};
