import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
const { breakpoints } = require("../shared/style/breakpoints");

export const useResponsive = () => {
  const [client, setClient] = useState(false);
  useEffect(() => {
    if (window) {
      setClient(true);
    }
    return () => {
      setClient(false);
    };
  }, []);

  const isXSmall = useMediaQuery({
    minWidth: breakpoints.xs,
  });
  const isSmall = useMediaQuery({
    minWidth: breakpoints.sm,
  });
  const isMedium = useMediaQuery({
    minWidth: breakpoints.md,
  });
  const isLarge = useMediaQuery({
    minWidth: breakpoints.lg,
  });
  const isXLarge = useMediaQuery({
    minWidth: breakpoints.xl,
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  return {
    client,
    isMobile: client,
    isXSmall: client && isXSmall,
    isSmall: client && isSmall,
    isMedium: client && isMedium,
    isPortrait: client && isPortrait,
    isLarge: client && isLarge,
    isXLarge: client && isXLarge,
  };
};

export const useResponsiveLayout = () => {
  const { isMobile, isXSmall, isSmall, isMedium, isPortrait } = useResponsive();

  const isMediumAndPortrait = isMedium && isPortrait;

  const showSectionTitles =
    isMobile || isXSmall || isSmall || isMediumAndPortrait;

  return {
    showSectionTitles,
    showCarouselContent: !showSectionTitles || (isMedium && !isPortrait),
  };
};
