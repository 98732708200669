import React from "react";
import Logo from "../logo/Logo.component";
import Menu from "../menu/Menu.component";

import "./header.scss";

const Header = () => {
  return (
    <header className={"header-wrapper"}>
      <Logo className={"header-link"} />
      <Menu />
    </header>
  );
};
export default Header;
