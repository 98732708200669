import React, { memo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link, useI18next } from "gatsby-plugin-react-i18next";

import "./footer.scss";

const Footer = () => {
  const year = new Date().getFullYear();
  const { termsFr, termsEn, privacyFR, privacyEn } = useStaticQuery(
    graphql`
      query {
        termsFr: contentfulLayoutAllPages(
          identifier: { eq: "page_terms-conditions" }
          node_locale: { eq: "fr" }
        ) {
          title
        }
        termsEn: contentfulLayoutAllPages(
          identifier: { eq: "page_terms-conditions" }
          node_locale: { eq: "en" }
        ) {
          title
        }
        privacyFR: contentfulLayoutAllPages(
          identifier: { eq: "page_privacy-policy" }
          node_locale: { eq: "fr" }
        ) {
          title
        }
        privacyEn: contentfulLayoutAllPages(
          identifier: { eq: "page_privacy-policy" }
          node_locale: { eq: "en" }
        ) {
          title
        }
      }
    `
  );

  const { language } = useI18next();

  return (
    <footer className={"footer-wrapper"}>
      <p>© {year} - Game On</p>
      <div className="footer_links-wrapper">
        <Link to={"/terms-conditions"}>
          {language === "fr" ? termsFr.title : termsEn.title}
        </Link>
        <p>-</p>
        <Link to={"/privacy-policy"}>
          {language === "fr" ? privacyFR.title : privacyEn.title}
        </Link>
      </div>
    </footer>
  );
};
export default memo(Footer);
