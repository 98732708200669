import React, { createContext, ReactNode, useState } from "react";
import { Helmet } from "react-helmet";

// neede to import tailwind in all the files
import "../../shared/style/tailwind.scss";

type Props = {
  children: ReactNode;
};

export type TPageContext = {
  isScrollable: boolean;
  displayMenu: boolean;
  toggleScroll: () => void;
  toggleMenu: () => void;
};

export const PageContext = createContext<TPageContext | undefined>(undefined);

export const PageLayout = ({ children }: Props) => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [isScrollable, setIsScrollable] = useState(true);

  const toggleScroll = () => setIsScrollable(!isScrollable);
  const toggleMenu = () => setDisplayMenu(!displayMenu);
  const pageContext = { isScrollable, displayMenu, toggleScroll, toggleMenu };

  return (
    <PageContext.Provider value={pageContext}>
      <Helmet
        bodyAttributes={{ class: `${isScrollable ? "" : "no-scroll"}` }}
      />
      {children}
    </PageContext.Provider>
  );
};
