import React, { ReactNode } from "react";
import { Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import {
  ContentfulVideo,
  JsonContent,
} from "../../../shared/contentful/contentful.types";

import { renderRichText } from "gatsby-source-contentful/rich-text";
import { VideoClip } from "./VideoClip.component";

import "./intro.scss";

type VideoMedia = {
  file: { url: string };
};

export type IntroClip = {
  id: string;
  media: VideoMedia;
};

type Props = {
  introClips: ContentfulVideo[];
  intro: JsonContent;
};

const renderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => (
      <p className={"intro-text"}>{children}</p>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: (node: ReactNode) => <strong>{node}</strong>,
  },
};

const Intro = ({ introClips, intro }: Props) => {
  const noVideo = introClips.some((video) => !video.asset?.file.url);

  if (noVideo) return null;

  return (
    <div className={"introclips-wrapper"}>
      <VideoClip introClips={introClips} />
      <div className="intro-content">
        {renderRichText(intro, renderOptions)}
      </div>
    </div>
  );
};
export default Intro;
