module.exports = {
  breakpoints: {
    xs: "424px",
    sm: "768px",
    md: "1024px",
    lg: "1280px",
    xl: "1440px",
    xxl: "1920px",
  },
};
